export const entityLoadingMixin = {
  computed: {
    isDataFetched() {
      return !this.isLoading && !!this.fetchedData;
    },
    hasLoadingError() {
      return !this.isLoading && this.loadingError;
    },
  },
  data() {
    return {
      isLoading: false,
      loadingError: null,
      fetchedData: null,
      initialParams: null,
      dispatchPath: null,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      const self = this;
      this.initializeLoading(self);
      this.initialParams = to.params;
      this.fetchData(to.params)
        .then((data) => {
          self.setData(data, null);
        })
        .catch((err) => {
          self.setData(null, err);
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.initializeLoading(vm);
      vm.initialParams = to.params;
      vm.fetchData(to.params)
        .then((data) => {
          vm.setData(data, null);
        })
        .catch((err) => {
          vm.setData(null, err);
        });
    });
  },
  beforeRouteUpdate(to, from, next) {
    const self = this;
    this.initializeLoading(self);

    this.fetchData(to.params)
      .then((data) => {
        self.setData(data, null);
      })
      .catch((err) => {
        self.setData(null, err);
      })
      .finally(() => {
        next();
      });
  },
  methods: {
    initializeLoading(instance) {
      instance.isLoading = true;
      instance.fetchedData = null;
      instance.loadingError = null;
    },
    finalizeLoading(instance) {
      instance.isLoading = false;
    },
    applyDataTransformation(fetchedData) {
      // Default, nothing to do.
      return new Promise((resolve) => {
        // console.log('Applying NoOp transformation...');
        resolve(fetchedData);
      });
    },
    // eslint-disable-next-line no-unused-vars
    postDataSet(fetchedData) {
      // Default, nothing to do.
      // console.log('Default postDataSet');
    },
    setData(fetchedData, err) {
      // console.log('setData', { fetchedData, err });
      if (err) {
        this.loadingError = err.toString();
      } else {
        this.loadingError = null;
      }
      if (fetchedData) {
        const self = this;
        this.applyDataTransformation(fetchedData).then((transformedData) => {
          // console.log('Transformed Data', transformedData);
          self.fetchedData = transformedData;
          self.postDataSet(transformedData);
          self.finalizeLoading(self);
        });
      } else {
        // console.log('No data was fetched...');
        this.fetchedData = null;
        this.postDataSet();
        this.finalizeLoading(this);
      }
    },
    // eslint-disable-next-line no-unused-vars
    fetchData(params) {
      if (this.dispatchPath) {
        return this.$store.dispatch(this.dispatchPath, params);
      } else {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
          reject('Load details not implemented');
        });
      }
    },
    refreshData() {
      this.fetchData(this.initialParams)
        .then((data) => {
          this.setData(data, null);
        })
        .catch((err) => {
          this.setData(null, err);
        });
    },
  },
};
