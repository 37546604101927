var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_c('h2',[_vm._v(_vm._s(_vm.status.toUpperCase())+" Activities")])]),(_vm.isLoading)?_c('CCardBody',[_c('h2',[_vm._v(" Loading... "),_c('CSpinner',{staticStyle:{"width":"2rem","height":"2rem","border":"0.125em solid currentColor","border-right-color":"transparent","margin-left":"10px"},attrs:{"color":"primary"}})],1)]):(_vm.hasLoadingError)?_c('CCardBody',[_vm._v(" An error has occurred while trying to load Workflows. "),_c('p',{staticClass:"pt-3"},[_c('strong',[_vm._v(_vm._s(_vm.loadingError))])])]):_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.workflows,"fields":_vm.workflowFields,"table-filter":"","sorter":""},scopedSlots:_vm._u([{key:"statusLabel",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(item.statusLabel))])]}},{key:"clientCode",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[(item.clientCode)?_c('router-link',{attrs:{"to":{ name: 'view-client', params: { name: item.clientCode } }}},[_vm._v(_vm._s(item.clientCode))]):_vm._e()],1)]}},{key:"title",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_c('router-link',{attrs:{"to":{ name: 'view-workflow', params: { id: item.workflowId } }}},[_vm._v(_vm._s(item.title))])],1)]}},{key:"startedAt",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.formatTimestamp(item.startedAt)))])]}},{key:"completedTaskCount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-left"},[_vm._v(" "+_vm._s(item.completedTaskCount + item.skippedTaskCount)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }