<template>
  <CCard>
    <CCardHeader>
      <h2>{{ status.toUpperCase() }} Activities</h2>
    </CCardHeader>
    <CCardBody v-if="isLoading">
      <h2>
        Loading...
        <CSpinner
          color="primary"
          style="
            width: 2rem;
            height: 2rem;
            border: 0.125em solid currentColor;
            border-right-color: transparent;
            margin-left: 10px;
          "
        />
      </h2>
    </CCardBody>
    <CCardBody v-else-if="hasLoadingError">
      An error has occurred while trying to load Workflows.
      <p class="pt-3">
        <strong>{{ loadingError }}</strong>
      </p>
    </CCardBody>
    <CCardBody v-else>
      <CDataTable
        :items="workflows"
        :fields="workflowFields"
        table-filter
        sorter
      >
        <template #statusLabel="{ item }">
          <td class="align-middle">{{ item.statusLabel }}</td>
        </template>
        <template #clientCode="{ item }">
          <td class="align-middle">
            <router-link
              v-if="item.clientCode"
              :to="{ name: 'view-client', params: { name: item.clientCode } }"
              >{{ item.clientCode }}</router-link
            >
          </td>
        </template>
        <template #title="{ item }">
          <td class="align-middle">
            <router-link
              :to="{ name: 'view-workflow', params: { id: item.workflowId } }"
              >{{ item.title }}</router-link
            >
          </td>
        </template>
        <template #startedAt="{ item }">
          <td class="align-middle">{{ formatTimestamp(item.startedAt) }}</td>
        </template>
        <template #completedTaskCount="{ item }">
          <td class="align-left">
            {{ item.completedTaskCount + item.skippedTaskCount }}
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>
<script>
import { formatTimestamp } from '@/utils/format';
import { entityLoadingMixin } from '@/mixins/entity-loading-mixin';

const WORKFLOW_FIELDS = [
  {
    key: 'statusLabel',
    label: 'Status',
    sorter: false,
  },
  {
    key: 'clientCode',
    label: 'Client',
    sorter: true,
  },
  {
    key: 'title',
    label: 'Title',
    sorter: false,
  },
  {
    key: 'inProgressTaskCount',
    label: 'Running Tasks',
    sorter: false,
  },
  {
    key: 'completedTaskCount',
    label: 'Completed Tasks',
    sorter: false,
  },
  {
    key: 'pendingTaskCount',
    label: 'Pending Tasks',
    sorter: false,
  },
  {
    key: 'startedBy',
    label: 'Started By',
    sorter: false,
  },
  {
    key: 'startedAt',
    label: 'Started',
    sorter: false,
  },
];

export default {
  name: 'ListWorkflows',
  mixins: [entityLoadingMixin],
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return 'all';
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    workflowFields() {
      return WORKFLOW_FIELDS;
    },
    workflows() {
      return this.fetchedData || [];
    },
  },
  methods: {
    formatTimestamp,
    fetchData(params) {
      return this.$store.dispatch(
        'workflows/fetchWorkflows',
        params.status || this.status || 'all'
      );
    },
  },
};
</script>
